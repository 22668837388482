<template>
  <div>
    <section class="header">
      <strong id="headerTitle">灯影 <br />畅游未来</strong>
    </section>
    <section class="part part1">
      <div class="part_title"><strong>企业介绍</strong><br /><span>company profile</span></div>
      <div class="part_contain">
        <div class="text">
          灯塔互动于2016年07月01日成立，系灯塔财经信息有限公司（灯塔财经）旗下<br />
          全资控股子公司。公司致力于各领域内的科技创新，运用人工智能及大数据，为客<br />
          户提供全方位的产品和服务，帮助用户搭建从内容创作-渠道分发-获客管<br />
          理-用户分析-精准营销-商业变现的全流程服务。
        </div>
        <div class="bg"></div>
      </div>
    </section>
    <section class="part part2">
      <div class="part_title">
        <strong>服务成就</strong><br />
        <span>Service achievements</span><br />
        <b>目前灯塔合作的客户以湖北为中心，辐射全国，为数百家企业提供全面的产品服务</b>
      </div>
      <div id="trigger1" style="height: 1px"></div>
      <ul class="part_contain" id="part2Contain">
        <li>
          <strong class="turn_num">0</strong><span>+</span><br />
          <b>合作企业</b>
        </li>
        <li>
          <strong class="turn_num">0</strong><span>万</span><br />
          <b>个人用户数</b>
        </li>
        <li>
          <strong class="turn_num">10</strong><span>+</span><br />
          <b>覆盖行业</b>
        </li>
      </ul>
    </section>
    <section class="part part3">
      <div class="part_title">
        <strong>我们的团队</strong><br />
        <span>our team</span><br />
        <b
          >目前团队人员70%为产品开发人员，核心骨干来自于腾讯、阿里、百度、恒生电子等公司，在大数据、人工智能、金融软件服务<br />领域积累10年以上的丰富经验。</b
        >
      </div>
      <ul class="part_contain">
        <li>
          <div class="name">
            <strong>朱留锋</strong><br />
            <span>创始人&CEO</span>
          </div>
          <div class="position">
            曾任腾讯市场部总监、华中分公司总经理、腾讯科技（武汉）有限<br />公司总经理、腾讯大楚网总裁（兼）
          </div>
        </li>
        <li>
          <div class="position">
            曾任腾讯电商公司(ECC)旅游事业部助理总经理；艺龙旅行网营销<br />副总裁、宝洁公司CBD大区经理
          </div>
          <div class="name">
            <strong>荣强</strong><br />
            <span>创始人&CMO</span>
          </div>
        </li>
        <li>
          <div class="name">
            <strong>田淑宁</strong><br />
            <span>创始人&CTO</span>
          </div>
          <div class="position">
            曾任腾讯数据平台部、浏览器产品部、智能平台产品部等相关大规<br />模后台系统架构设计师
          </div>
        </li>
        <li>
          <div class="position">
            曾任职于中国银行、南方证券、信诚人寿，拥有20年股票、基金、<br />保险、私募等投资理财经验
          </div>
          <div class="name">
            <strong>任涛</strong><br />
            <span>副总裁</span>
          </div>
        </li>
      </ul>
    </section>
    <section class="part part4">
      <div class="part_title">
        <strong>合作伙伴</strong><br />
        <span>cooperative partner</span>
      </div>
      <div class="part_contain">
        <div class="swiper-container swiper1">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(partner, index) in partnerData.slice(0, 7)" :key="index">
              <img :src="partner.icon" alt="" />
            </div>
          </div>
        </div>
        <div class="swiper-container swiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(partner, index) in partnerData.slice(7, 14)" :key="index">
              <img :src="partner.icon" alt="" />
            </div>
          </div>
        </div>
        <div class="swiper-container swiper3">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(partner, index) in partnerData.slice(14)" :key="index">
              <img :src="partner.icon" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="less" scoped>
@import "./aboutUs.less";
</style>
<script>
const partnerData = [
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon1.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon2.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon3.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon4.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon5.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon6.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon7.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon8.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon9.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon10.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon11.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon12.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon13.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon14.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon15.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon16.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon17.png"),
  },
  {
    icon: require("@/assets/images/homePage/aboutus_part4_icon18.png"),
  },
];
import "swiper/css/swiper.min.css";
import Swiper from "swiper";
import ScrollReveal from "scrollreveal";
import anime from "animejs/lib/anime.es.js";
export default {
  data() {
    return {
      partnerData,
    };
  },
  methods: {
    initSwiper() {
      this.swiper1 = new Swiper(".swiper1", {
        direction: "horizontal",
        slidesPerView: 7,
        spaceBetween: 20,
        speed: 3000,
        loop: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
      this.swiper2 = new Swiper(".swiper2", {
        direction: "horizontal",
        slidesPerView: 5,
        spaceBetween: 20,
        speed: 3000,
        loop: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          reverseDirection: true,
        },
      });
      this.swiper3 = new Swiper(".swiper3", {
        direction: "horizontal",
        slidesPerView: 4,
        spaceBetween: 20,
        speed: 3000,
        loop: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    initAnimate() {
      ScrollReveal().reveal("#headerTitle", {
        origin: "left",
        distance: "1000px",
        duration: 1000,
        reset: true
      });
      ScrollReveal().reveal(".part_title", {
        origin: "top",
        distance: "100px",
        duration: 1000,
        reset: true
      });
      this.scrollmagic({
        trigger: "#trigger1",
        duration: 100,
        ele: "#part2Contain",
        callback: (e) => {
          console.log(e);
          if (e.progress == 1) {
            anime({
              targets: ".part2 .turn_num",
              innerHTML: function(el, i) {
                if(i == 0) {
                  return [0, 100]
                } else if(i == 1) {
                  return [0, 1000]
                } else {
                  return [0, 10]
                }
              },
              round: 10,
              duration: 2000,
              easing: "linear",
            });
          }
        },
      });
    },
    scrollmagic({ trigger, ele, duration, offset = 0, css = {}, callback }) {
      const scene = this.$scrollmagic.scene({
        triggerElement: trigger,
        // {0,0.5,1} - animations starts from {top,center,end} of window
        triggerHook: 0.8,
        // Duration of animation
        duration: duration,
        offset: offset,
      });
      scene
        .on("end", function (e) {
          callback && callback(e);
        })
        // Declaration of animation and attaching to element
        .setTween(
          TweenMax.to(ele, 0.5, {
            css: css,
          })
        );
      this.$scrollmagic.addScene(scene);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper();
      this.initAnimate();
    });
  },
  beforeDestroy() {
    this.swiper1.destroy();
    this.swiper2.destroy();
    this.swiper3.destroy();
  },
};
</script>
